// @flow
import axios from 'axios';
import { QueryClient } from 'react-query';
import { throttleAdapterEnhancer } from 'axios-extensions';

import { logoutUser } from 'utils/user';
import { cacheGet } from 'utils/localStorageCache';
import { CACHE_TIME } from 'constants/constants';

import { getDeviceId, getGuestToken } from 'utils';
import { currentLanguage } from 'store/constants/language';
import type { Params } from './getQuery';
import getQuery from './getQuery';

window.productCache = new Map();
const API_PATH = process.env.REACT_APP_API_URL;

// these errors happen on expired or malformed token, remove token from localStorage and reload page
const errorsRequiringReload = ['token', 'signature', 'unauthorized'];
const defaultUnknownErrorMsg = 'Something went wrong! Please try again.';

// const sentryCapture = err =>
//   process.env.NODE_ENV === 'production' && window.Sentry && window.Sentry.captureException(err);

const errorHandler = (err: any) => {
  console.log('API', err.response);
  // sentryCapture(err);
  try {
    const message = err?.response?.data?.message;

    if (message) {
      for (const _error of errorsRequiringReload) {
        const _message = message.toLowerCase();
        if (_message.includes(_error)) {
          logoutUser();
          return window.location.reload();
        }
      }
      return Promise.reject(message);
    }
    return Promise.reject(defaultUnknownErrorMsg);
  } catch (_) {
    return Promise.reject(defaultUnknownErrorMsg);
  }
};

const axiosInstance = axios.create({
  baseURL: API_PATH,
  timeout: 25 * 1000,
  headers: {
    // build id picked from netlify
    'x-app-version': 'novelship.pages.dev',
    'x-device-id': getDeviceId(),
    'x-locale': currentLanguage,
  },
  adapter: throttleAdapterEnhancer(axios.defaults.adapter, { threshold: 2 * 1000 }),
});

const clearTokenCache = () => delete axiosInstance.defaults.headers.common.Authorization;

const api = () => {
  const token = cacheGet('token', '');
  if (token) {
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
    return axiosInstance;
  }

  const guestToken = getGuestToken();
  if (guestToken) {
    axiosInstance.defaults.headers.common['guest-token'] = guestToken;
    return axiosInstance;
  }

  delete axiosInstance.defaults.headers.common.Authorization;
  return axiosInstance;
};

const isExternalAPI = path => /:\/\//.test(path);
const responseHandler = res => res.then(resp => resp.data).catch(errorHandler);

const fetch = (path: string, params: Params = {}) =>
  responseHandler(isExternalAPI(path) ? axios.get(path) : api().get(getQuery(path, params))).then(
    response => {
      if (path.startsWith('products') || path.includes('featured')) {
        ['results', 'latest', 'mostPopular', 'newList'].forEach(list => {
          const products = response && response[list];
          if (products && products.length) {
            products.forEach(p => window.productCache.set(p.name_slug, p));
          }
        });
      }
      return response;
    }
  );

const post = (path: string, body: mixed = {}, options: mixed = {}) =>
  responseHandler(
    isExternalAPI(path) ? axios.post(path, body, options) : api().post(path, body, options)
  );

const put = (path: string, body: mixed = {}, options: mixed = {}) =>
  responseHandler(
    isExternalAPI(path) ? axios.put(path, body, options) : api().put(path, body, options)
  );

const remove = (path: string) =>
  responseHandler(isExternalAPI(path) ? axios.delete(path) : api().delete(path));

// const patch = (path: string, body: mixed = {}) =>
//   responseHandler(isExternalAPI(path) ? axios.patch(path, body) : api().patch(path, body));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: ({ queryKey }) => fetch(...queryKey),
      cacheTime: CACHE_TIME.short,
      retry: 3,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    },
  },
});

export default { fetch, post, put, remove };
export { errorHandler, queryClient, clearTokenCache };
